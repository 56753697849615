
$(document).ready(function(){
    $("#global-loader").fadeOut("slow");
    $('#carouselAutentication').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        autoplay: true
    });
});