window.Popper = require('popper.js').default;
window.$ = window.jQuery = require('jquery');
require('toastr');

require('../assets/plugins/bootstrap/js/bootstrap.bundle.min.js');
require('../assets/plugins/bootstrap/js/popper.min.js');
require('../assets/js/jquery.sparkline.min.js');
require('../assets/js/circle-progress.min.js');
require('../assets/plugins/chart/utils.js');
require('../assets/plugins/peitychart/jquery.peity.min.js');
require('../assets/plugins/peitychart/peitychart.init.js');
require('../assets/plugins/sweet-alert/sweetalert.min.js');
require('../assets/plugins/nestable/jquery.nestable.js');
require('../assets/plugins/slick/slick.js');
require('../assets/js/custom-auth.js');

